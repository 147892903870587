import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';
import {interval, Subscription} from 'rxjs';
import { UserRepositoryService } from '../../../modules/user-repository/user-repository.service';
import { CoreService } from '../../services/core.service';
import {CoreEvent, CoreEventsService} from '../../services/core-events.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit, OnDestroy {

    constructor(private coreService: CoreService, private coreEventService: CoreEventsService, private userRepository: UserRepositoryService, private authService: AuthenticationService, private router: Router) { }

    get progress(): number {
      return this.coreService.currentProgress;
    }

    private coreServiceSubscription: Subscription;
    private timerSubscription: Subscription;
    private timerCount = 1;
    private maxTimerCount = 10;
    showTimeoutMessage = false;
    readonly window = window;

    ngOnInit(): void {
        this.coreServiceSubscription = this.coreEventService.events$.subscribe((event) => this.coreEventsHandler(event));
        if (this.coreService.isReady) {
          this.navigate();
        }
        this.timerSubscription = interval(1000).subscribe(() => this.timerEvents());
    }

    ngOnDestroy(): void {
      this.coreServiceSubscription?.unsubscribe();
      this.timerSubscription?.unsubscribe();
    }

    private timerEvents() {
      if (this.timerCount === this.maxTimerCount) {
        this.timerSubscription.unsubscribe();
        this.showTimeoutMessage = true;
      }
      this.timerCount++;
    }

    private coreEventsHandler(event: CoreEvent) {
        if (!this.coreService.isReady) { return; }
        if (event.type === 'app_error') {
          this.router.navigateByUrl('error');
        }
        if (event.type === 'app_ready') {
          this.navigate();
        }
    }

    private navigate() {
      if (this.coreService.hasPractices()) { this.router.navigateByUrl('dashboard'); }
      if (!this.coreService.hasPractices() && this.coreService.hasInvites()) { this.router.navigateByUrl('invites'); }
      if (!this.coreService.hasPractices() && !this.coreService.hasInvites()) { this.router.navigateByUrl('error'); }
    }
}
