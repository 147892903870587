import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { Practice } from 'src/app/modules/practice-repository/entities/practice';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';

@Component({
    selector: 'practice-switcher',
    templateUrl: './practice-switcher.component.html',
    styleUrls: ['./practice-switcher.component.scss'],
    standalone: false
})
export class PracticeSwitcherComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isNotHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => !result.matches),
      shareReplay()
    );

    constructor(private coreService: CoreService, private router: Router, private breakpointObserver: BreakpointObserver) { }

    ngOnInit(): void {
    }

    selectPractice(id: number) {
        if (id === this.selectedPractice.id) { return; }
        this.coreService.selectPractice(id);
        this.router.navigateByUrl('/');
    }

    get displayPracticeList(): boolean {
        if (this.practices === null) { return false; }
        return this.practices.length > 1;
    }

    get selectedPractice(): Practice {
        return this.coreService.selectedPractice;
    }

    get selectedPracticeName(): string {
        return (!!this.selectedPractice) ? this.selectedPractice.name : 'No Practice Selected';
    }


    get practices(): Practice[] {
        return this.coreService.practices;
    }

    get enabled(): boolean {
        return this.displayPracticeList;
    }

}
