<div class="app-menu">
    <div class="app-menu-grid">
      <a class="dropdown-item app-icon" [class.disabled]="dashboardModuleDisabled" [routerLink]="dashboardModuleDisabled ? null : ['/dashboard']"><mat-icon class="md-36">dashboard</mat-icon>dashboard</a>
      <a class="dropdown-item app-icon" [class.disabled]="calendarModuleDisabled" [routerLink]="calendarModuleDisabled ? null : ['/calendar']"><mat-icon class="md-36">date_range</mat-icon>Calendar</a>
      <a class="dropdown-item app-icon" [class.disabled]="reportBuilderModuleDisabled" [routerLink]="reportBuilderModuleDisabled ? null : ['/theater-slates']"><mat-icon class="md-36">add_to_queue</mat-icon>Theatre Slates</a>
      <a class="dropdown-item app-icon" [class.disabled]="smsModuleDisabled" [routerLink]="smsModuleDisabled ? null : ['/emails']"><mat-icon class="md-36">sms</mat-icon>SMS</a>
      <a class="dropdown-item app-icon" [class.disabled]="emailModuleDisabled" [routerLink]="emailModuleDisabled ? null : ['/emails']"><mat-icon class="md-36">email</mat-icon>Emails</a>
      <a class="dropdown-item app-icon" [class.disabled]="uploadsModuleDisabled" [routerLink]="uploadsModuleDisabled ? null : ['/uploads']"><mat-icon class="md-36">cloud_upload</mat-icon>Uploads</a>
      <a class="dropdown-item app-icon" [class.disabled]="complianceModuleDisabled" [routerLink]="complianceModuleDisabled ? null : ['/compliance']"><mat-icon class="md-36">playlist_add_check</mat-icon>Compliance</a>
      <a class="dropdown-item app-icon" [class.disabled]="reportBuilderModuleDisabled" [routerLink]="reportBuilderModuleDisabled ? null : ['/report-builder']"><mat-icon class="md-36">table_chart</mat-icon>Report Builder</a>
      <a class="dropdown-item app-icon" [class.disabled]="analyticsModuleDisabled" [routerLink]="analyticsModuleDisabled ? null : ['/emails']"><mat-icon class="md-36">bar_chart</mat-icon>Analytics</a>
      <a class="dropdown-item app-icon" [class.disabled]="settingsModuleDisabled" [routerLink]="settingsModuleDisabled ? null : ['/settings']"><mat-icon class="md-36">settings</mat-icon>Settings</a>
      <a class="dropdown-item app-icon" [class.disabled]="adminModuleDisabled" [routerLink]="adminModuleDisabled ? null : ['/admin']"><mat-icon class="md-36">lock</mat-icon>Admin</a>
    </div>
</div>
