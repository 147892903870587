import {Component, OnInit} from '@angular/core';
import {ApplicationConfig} from 'src/app/modules/base/entities/application-config';
import {CoreService} from '../../services/core.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.scss'],
    standalone: false
})
export class AppMenuComponent implements OnInit {

  configuration: ApplicationConfig;

  constructor(private coreService: CoreService) {
  }

  get dashboardModuleDisabled(): boolean {
    return !this.configuration.dashboardModuleEnabled;
  }

  get complianceModuleDisabled(): boolean {
    return !this.configuration.complianceModuleEnabled;
  }

  get reportBuilderModuleDisabled(): boolean {
    return !this.configuration.reportBuilderModuleEnabled;
  }

  get settingsModuleDisabled(): boolean {
    return !this.configuration.settingsModuleEnabled;
  }

  get adminModuleDisabled(): boolean {
    return !this.configuration.adminModuleEnabled || !this.coreService.isSuperuser;
  }

  get smsModuleDisabled(): boolean {
    return true;
  }

  get emailModuleDisabled(): boolean {
    return true;
  }

  get uploadsModuleDisabled(): boolean {
    return true;
  }

  get analyticsModuleDisabled(): boolean {
    return true;
  }

  get calendarModuleDisabled(): boolean {
    return !this.configuration.calendarModuleEnabled;
  }

  ngOnInit(): void {
    this.configuration = this.coreService.configuration;
  }

  close() {

  }

}
