import {Directive, HostListener, OnDestroy, OnInit} from '@angular/core';
import {UserRepositoryService} from '../../modules/user-repository/user-repository.service';
import {AuthenticationService} from '../auth/authentication.service';
import {interval, Subject, Subscription} from 'rxjs';
import {LocalStorageService} from '../../modules/storage/local-storage.service';
import {environment} from '../../../environments/environment';

const STORAGE_KEY = '_xmedical_idle_timeout_' + (environment.production ? 'prod' : 'debug');
@Directive({
    selector: '[appIdleTimeout]',
    standalone: false
})
export class IdleTimeoutDirectiveDirective implements OnInit, OnDestroy{

  private timerSubscription: Subscription;
  private timeout = 15 * 60 * 1000;
  private debounceTimeout = 1000;
  private eventsSubject: Subject<void> = new Subject();
  private debounceTimer: any = null;

  constructor(private userRepository: UserRepositoryService, private authService: AuthenticationService, private localStorage: LocalStorageService) { }



  start(timeout: number) {
    this.timeout = timeout;
    this.updateExpiredTime();
    this.startTimer();
  }

  stop() {
    this.timerSubscription?.unsubscribe();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.stop();
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('click', ['$event'])
  private updateExpiredTime(event = null) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      this.localStorage.setItem(STORAGE_KEY, Date.now() + this.timeout);
    }, this.debounceTimeout);
  }

  @HostListener('visibilitychange', ['$event'])
  private onVisibilityChanged(event = null) {
    console.log(event);
    if (document.visibilityState === 'visible') {
      this.start(this.timeout);
    } else {
      this.stop();
    }
  }

  private startTimer() {
    this.updateExpiredTime();
    this.timerSubscription = interval(5000).subscribe(() => {
      const expiredTime = parseInt(this.localStorage.getItem(STORAGE_KEY, 0), 10);
      if (expiredTime < Date.now()) {
        this.eventsSubject.next();
      }
    });
  }

}
