import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {IdleTimeoutService} from '../../services/idle-timeout.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {LocalStorageService} from '../../../modules/storage/local-storage.service';
import {WebsocketService} from '../../services/websocket.service';
import {CoreService} from '../../services/core.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ApplicationConfig} from '../../../modules/base/entities/application-config';
import {AuthEvent} from '../../auth/auth-event';
import {AppTabsService} from '../../services/tabs.service';
import {CoreEventsService} from '../../services/core-events.service';

@Component({
    selector: 'app-authenticated',
    templateUrl: './authenticated.component.html',
    styleUrls: ['./authenticated.component.scss'],
    standalone: false
})
export class AuthenticatedComponent implements OnInit, OnDestroy {
  isLocked = false;
  authEventSubscription: Subscription;
  coreEventSubscription: Subscription;
  timeoutSubscription: Subscription;
  timeout = 15 * 60 * 1000;
  constructor(
    private idleTimeoutService: IdleTimeoutService,
    private authService: AuthenticationService,
    private localStorage: LocalStorageService,
    private pusherService: WebsocketService,
    private coreService: CoreService,
    private coreEventService: CoreEventsService,
    private tabsService: AppTabsService
  ) {
  }

  ngOnInit(): void {
    this.timeout = environment.idleTimeout;
    this.authEventSubscription = this.authService.events$.subscribe(event => this.authEventHandler(event));
    this.pusherService.subscribe('events-channel', 'config', (config: ApplicationConfig) => {
      this.coreService.updateConfig(config);
    });
    this.coreEventSubscription = this.coreEventService.events$.subscribe(ev => {
      if (ev.type === 'settings_loaded') {
        this.timeout = this.coreService.settings.lockoutTime * 60 * 1000;
        this.startIdleTimer();
      }
    });
    if (this.coreService.settings) {
      this.timeout = this.coreService.settings.lockoutTime * 60 * 1000;
      this.startIdleTimer();
    }
    this.idleTimeoutService.events$.subscribe(() => this.timeoutEventHandler());
    this.isLocked = this.localStorage.getItem('_xmedical_locked', false);
    this.startIdleTimer();
  }

  ngOnDestroy(): void {
    if (this.authEventSubscription) {
      this.authEventSubscription.unsubscribe();
    }
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }
  }

  logout() {
    this.stopIdleTimer();
    this.authService.logout();
  }

  startIdleTimer() {
    if (!this.isLocked) { return; }
    if (!this.idleTimeoutService.isRunning) {
      this.idleTimeoutService.start(this.timeout);
    }
    this.isLocked = false;
    this.localStorage.setItem('_xmedical_locked', this.isLocked);
  }

  get hasTabs(): boolean {
    return this.tabsService.hasTabs();
  }

  private timeoutEventHandler() {
    console.log('timed out');
    this.isLocked = true;
    this.idleTimeoutService.stop();
    this.localStorage.setItem('_xmedical_locked', true);
  }

  private stopIdleTimer() {
    this.isLocked = false;
    this.idleTimeoutService.stop();
    this.localStorage.setItem('_xmedical_locked', false);
  }

  private authEventHandler(event: AuthEvent) {
    if (event.type === 'login') {
      this.startIdleTimer();
    }
    if (event.type === 'logout') {
      this.stopIdleTimer();
    }
  }


}
