import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AppTabsService, Tab, TabEvent} from 'src/app/core/services/tabs.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CoreEvent, CoreEventsService} from '../../services/core-events.service';

@Component({
    selector: 'ui-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    standalone: false
})
export class TabBarComponent implements OnInit, AfterViewInit, OnDestroy {

  activeTab = -1;
  tabs: Tab[];
  tabsOpen = false;
  layoutEventsSubscription: Subscription;
  tabEventsSubscription: Subscription;
  screenWidth = '100%';

  constructor(private tabService: AppTabsService, private coreEventService: CoreEventsService, private router: Router) {
  }

  get hasTabs(): boolean {
    return this.tabService.hasTabs();
  }

  get getTabHeight(): number {
    if (!this.showTabs || !this.tabsOpen) {
      return 0;
    }
    return this.tabs.length * 3;
  }

  get showTabs(): boolean {
    return this.tabs.length > 0;
  }

  ngOnInit(): void {
    this.tabs = this.tabService.getTabs();
    this.activeTab = this.tabService.getActiveTab();
    this.tabEventsSubscription = this.tabService.events$.subscribe(event => this.tabEventsHandler(event));
    this.layoutEventsSubscription = this.coreEventService.events$.subscribe(event => this.layoutEventHandler(event));

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.screenWidth = window.innerWidth + 'px';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.screenWidth = window.innerWidth + 'px';
    });
  }

  ngOnDestroy(): void {
    if (this.tabEventsSubscription) {
      this.tabEventsSubscription.unsubscribe();
    }
    if (this.layoutEventsSubscription) {
      this.layoutEventsSubscription.unsubscribe();
    }
  }

  isTabActive(tab: Tab): boolean {
    const tabPath = tab.route.match(/\d+/g);
    const urlPath = window.location.pathname.match(/\d+/g);
    return urlPath?.length > 0 && tabPath?.length > 0 && urlPath[0] === tabPath[0];
  }

  closeTab(event: MouseEvent, index: number) {
    event.preventDefault();
    event.stopPropagation();
    this.tabService.closeTab(index);
  }

  activateTab(route: string) {
    this.router.navigateByUrl(route);
  }

  toggleTabs() {
    this.tabsOpen = !this.tabsOpen;
  }

  isCurrentTabActive(index: number): boolean {
    return index === this.activeTab;
  }

  private layoutEventHandler(event: CoreEvent) {
    if (event.type === 'practice_change') {
      this.tabService.getTabs();
    }
  }

  private tabEventsHandler(event: TabEvent) {
    this.tabs = this.tabService.getTabs();
    this.activeTab = this.tabService.getActiveTab();
  }
}
